export default function ({ route, redirect }) {
  const isNewLayout = !!JSON.parse(
    window.localStorage.getItem('gleanin.isNewCampaignLayout')
  )

  if (!isNewLayout) return

  return redirect({
    name: 'events-event_id-campaigns-campaign_id-landing_page_url',
    params: {
      event_id: route.params.event_id,
      campaign_id: route.params.campaign_id,
    },
  })
}
