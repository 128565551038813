<template>
  <v-list>
    <v-list-group v-model="dropDownExpanded">
      <template #activator :close-on-content-click="true">
        <v-badge
          v-if="currentUser.unread_notifications > 0"
          color="red"
          :content="currentUser.unread_notifications"
          offset-x="30"
          offset-y="20"
        >
          <v-list-item-avatar color="accent">
            <span class="white--text" style="font-size: 17px">
              {{ nameInitials }}
            </span>
          </v-list-item-avatar>
        </v-badge>
        <v-list-item-avatar v-else color="accent">
          <span class="white--text" style="font-size: 17px">
            {{ nameInitials }}
          </span>
        </v-list-item-avatar>

        <v-list-item-content :title="currentUser.email" class="flex-fill">
          <v-list-item-title> {{ nameValue }} </v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div class="pl-1">
        <v-list-item v-if="currentUser.is_internal" link to="/admin/accounts">
          <v-list-item-icon>
            <v-icon small>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Admin </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'my_profile-details' }">
          <v-list-item-icon>
            <v-icon small>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> My Profile </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/notifications" data-test="my-profile">
          <v-list-item-icon>
            <v-icon small>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Notifications </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              v-if="currentUser.unread_notifications > 0"
              inline
              color="primary"
              class="font-weight-bold"
              :content="currentUser.unread_notifications"
            />
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-if="accountPresent"
          link
          :to="`/accounts/${account._meta.id}/subscription`"
        >
          <v-list-item-icon>
            <v-icon small>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Subscription & Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="accountPresent"
          link
          :to="`/accounts/${account._meta.id}/users`"
        >
          <v-list-item-icon>
            <v-icon small>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Users </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template
          v-if="currentUser.account_count > 1 || currentUser.is_internal"
        >
          <v-divider />

          <v-list-item exact link to="/accounts">
            <v-list-item-icon>
              <v-icon small>mdi-sync</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Switch account </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider />
        <v-list-item link data-test="logout-btn" @click="logout">
          <v-list-item-icon>
            <v-icon small>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list-group>
  </v-list>
</template>
<script>
import { isEmpty } from 'lodash-es'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    account: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menu: null,
      dropDownExpanded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapMutations(['resetState']),
    nameValue() {
      return isEmpty(this.account.name) ? this.fullUserName : this.account.name
    },
    accountPresent() {
      return !isEmpty(this.account)
    },
    fullUserName() {
      let result = ''
      if (!isEmpty(this.currentUser.first_name)) {
        result += `${this.currentUser.first_name} `
      }
      if (!isEmpty(this.currentUser.last_name)) {
        result += `${this.currentUser.last_name}`
      }
      return result
    },
    nameInitials() {
      let result = ''
      if (!isEmpty(this.currentUser.first_name)) {
        result += this.currentUser.first_name[0]
      }
      if (!isEmpty(this.currentUser.last_name)) {
        result += this.currentUser.last_name[0]
      }
      return result
    },
  },
  watch: {
    $route(to) {
      if (to && to.path.match(/^\/accounts\/[a-f0-9-]*\/events/)) {
        this.dropDownExpanded = false
      }
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout('local')

      this.$store.commit('resetState')
    },
  },
}
</script>

<style scoped>
.accounts-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
