<template>
  <v-app v-if="$auth.loggedIn">
    <div class="d-flex flex-grow-1">
      <!-- Navigation -->
      <v-navigation-drawer v-model="drawer" app permanent :clipped="true">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="overline grey--text">
                Admin Area
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <AdminSearch class="px-2" />

        <v-list dense><NavMenu :menu="items" /></v-list>
      </v-navigation-drawer>

      <TopBar />
      <v-main>
        <v-container fluid>
          <Nuxt />
        </v-container>
      </v-main>
    </div>

    <v-snackbar
      v-if="toast.show"
      :value="true"
      :timeout="toast.timeout"
      :color="toast.color"
      :min-width="50"
      bottom
    >
      {{ toast.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import NavMenu from '@/components/navigation/NavMenu'
import TopBar from '@/components/navigation/TopBar'
import AdminSearch from '@/components/AdminSearch'

export default {
  components: {
    TopBar,
    NavMenu,
    AdminSearch,
  },
  data() {
    return {
      drawer: null,
      items: [
        {
          icon: 'NavAccounts',
          text: 'Accounts',
          link: '/admin/accounts',
        },
        {
          icon: 'NavUsers',
          text: 'Users',
          link: '/admin/users',
        },
        {
          icon: 'NavPreviewer',
          text: 'Previewer',
          link: '/admin/preview',
        },
        {
          icon: 'NavImgixOptions',
          text: 'Imgix options',
          link: '/admin/imgix',
        },
      ],
    }
  },
  computed: {
    ...mapState('app', ['toast']),
  },
}
</script>
