<template>
  <v-app v-if="$auth.loggedIn">
    <div class="d-flex flex-grow-1">
      <!-- Navigation -->
      <v-navigation-drawer v-model="drawer" app permanent :clipped="true">
        <NavDrawerHeader :account="currentAccount" />

        <v-list dense><nav-menu :menu="items" /></v-list>

        <div v-if="eventNav" class="ml-3">
          <div class="ml-2 pa-1 overline">{{ currentEvent.title }}</div>
          <v-list dense> <nav-menu :menu="eventNav" /></v-list>
        </div>

        <template #append>
          <v-divider />
          <div class="pa-1">
            <v-btn
              block
              color="grey darken-2"
              class="text-left"
              target="_blank"
              href="https://resources.gleanin.com/knowledge"
              text
              x-large
            >
              <v-icon left color="red lighten-2"> mdi-lifebuoy </v-icon>
              Help
            </v-btn>
          </div>
          <div class="build-version">
            <span>Version: build-{{ buildVersion }}</span>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main>
        <template v-if="mainPadding">
          <InProductBanner />
          <v-container fluid>
            <StatusAlert
              v-if="subscription && subscription.metadata"
              :account-id="currentAccount._meta.id"
              :subscription-status="subscription.status"
              :resume-date="subscription.metadata.resume_date"
              :pause-date="subscription.metadata.pause_date"
              :cancelled-at="subscription.metadata.cancelled_at"
              :trial-end-at="subscription.metadata.trial_end"
              :current-term-end="subscription.metadata.current_term_end"
            />
            <Nuxt class="page-max-width" />
          </v-container>
        </template>
        <v-container v-else class="px-0 py-0 h-full" fluid>
          <Nuxt />
        </v-container>
      </v-main>
    </div>

    <v-snackbar
      v-if="toast.show"
      :value="true"
      :timeout="toast.timeout"
      :color="toast.color"
      :min-width="50"
      bottom
    >
      {{ toast.message }}
    </v-snackbar>
    <v-snackbar
      v-if="versionToast.show"
      :value="true"
      :timeout="-1"
      color="blue-grey darken-1"
      vertical
      right
    >
      <div>A New version of the App is available.</div>
      <div>Please refresh your browser.</div>

      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          class="mr-1"
          color="indigo"
          @click="$store.dispatch('app/hideNewVersionAvailable')"
        >
          Cancel
        </v-btn>
        <v-btn text v-bind="attrs" @click="reloadPage()"> Refresh </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavMenu from '@/components/navigation/NavMenu'
import NavDrawerHeader from '@/components/navigation/NavDrawerHeader'
import StatusAlert from '@/components/subscriptions/StatusAlert'
import InProductBanner from '@/components/InProductBanner'
import { isEmpty } from 'lodash-es'

export default {
  components: {
    NavMenu,
    NavDrawerHeader,
    StatusAlert,
    InProductBanner,
  },
  middleware: ['confirmation', 'account'],
  data() {
    return {
      drawer: null,
    }
  },
  computed: {
    ...mapState('app', ['toast', 'versionToast']),
    ...mapGetters({ get: 'api/get', currentAccount: 'currentAccount' }),
    mainPadding() {
      return !this.$route.params.variant_id
    },
    buildVersion() {
      return (process.env.buildHash || '').substring(0, 10)
    },
    currentEvent() {
      return this.get(`events/${this.$route.params.event_id}`)
    },
    items() {
      return [
        {
          icon: 'NavMyEvents',
          text: 'Events',
          link: this.myEventsLink,
        },
      ]
    },
    myEventsLink() {
      if (isEmpty(this.currentAccount)) {
        return '/accounts'
      } else {
        return `/accounts/${this.currentAccount._meta.id}/events`
      }
    },
    isConversionUtm() {
      return this.currentEvent.conversion_type === 'utm_convert'
    },
    eventNav() {
      if (this.$route.params.event_id) {
        const eventNav = [
          {
            icon: 'NavCampaigns',
            text: 'Campaigns',
            link: `/events/${this.$route.params.event_id}/campaigns`,
          },
          {
            icon: 'NavResults',
            text: 'Results',
            link: `/events/${this.$route.params.event_id}/results`,
          },
          {
            icon: 'NavSpeakers',
            text: 'Speakers',
            link: `/events/${this.$route.params.event_id}/speakers`,
          },
          {
            icon: 'NavSessions',
            text: 'Sessions',
            link: `/events/${this.$route.params.event_id}/sessions`,
          },
          {
            icon: 'NavCombinedUpload',
            text: 'Combined upload',
            link: `/events/${this.$route.params.event_id}/combined_upload`,
          },
          {
            icon: 'NavExhibitors',
            text: 'Exhibitors',
            link: `/events/${this.$route.params.event_id}/exhibitors`,
          },
          {
            icon: 'NavIntegrations',
            text: 'Integrations',
            link: `/events/${this.$route.params.event_id}/integrations`,
            regex: /^\/(events\/[0-9]*\/)?integrations/,
          },
        ]
        if (this.isConversionUtm) {
          eventNav.push(
            {
              icon: 'NavConversionTracking',
              text: 'Conversion tracking',
              link: `/events/${this.$route.params.event_id}/conversion_tracking`,
            },
            {
              icon: 'NavShareTool',
              text: 'Share tool',
              link: `/events/${this.$route.params.event_id}/share_tool`,
            }
          )
        } else {
          eventNav.push({
            icon: 'NavPopup',
            text: 'Conversion & pop-up',
            link: `/events/${this.$route.params.event_id}/registration_integration`,
          })
        }
        if (this.currentAccount.subscription.custom_fields) {
          eventNav.push({
            icon: 'NavCustomFields',
            text: 'Custom Fields',
            link: `/events/${this.$route.params.event_id}/custom_fields`,
          })
        }
        return eventNav
      } else {
        return false
      }
    },
    subscription() {
      return this.currentAccount.subscription
    },
  },
  methods: {
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>
<style scoped>
.build-version {
  font-size: 12px;
  text-align: center;
  padding-bottom: 2px;
}
.page-max-width {
  max-width: 1280px;
}
</style>
