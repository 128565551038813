export default function ({ route, $auth, redirect }) {
  const authToekn = $auth.$storage._state['_token.local']
  const parsedAuthToekn = JSON.parse(atob(authToekn.split('.')[1]))
  const mfaTypedAt = parsedAuthToekn.mfa_typed_at
  // 20 minutes
  const validTime = 5 * 60
  let mfaTimeout = mfaTypedAt + validTime
  // convert to millis
  mfaTimeout = mfaTimeout * 1000
  const nowDate = new Date().getTime()
  // time now greater than MFA timeout
  if (nowDate > mfaTimeout) {
    redirect({ name: 'my_profile-check_mfa', query: { redirect: route.path } })
  }
}
