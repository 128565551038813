export default function ({ store, route, redirect }) {
  const isOnTrial =
    store.getters.currentAccount.subscription.metadata.status === 'in_trial'

  if (isOnTrial) {
    return redirect({
      name: 'accounts-account_id-subscription',
      params: {
        account_id: route.params.account_id,
      },
    })
  }
}
