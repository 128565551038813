<template>
  <div>
    <v-carousel
      v-model="currentCarousel"
      :cycle="false"
      hide-delimiter-background
      :show-arrows="false"
      height="100%"
      hide-delimiters
    >
      <div>
        <v-carousel-item
          v-for="(slide, i) in carouselItems"
          :key="`slide-${i}`"
        >
          <div class="flex pt-5 pb-3">
            <v-img :src="slide.imgUrl" class="carousel-img" />
          </div>
          <div class="catamaran white--text carousel-title_font">
            {{ slide.title }}
          </div>
          <div class="catamaran white--text carousel-sub-title">
            {{ slide.info }}
          </div>
          <div
            class="v-carousel__controls"
            style="position: relative; margin-top: auto"
          >
            <!-- re-building the carousel-controls as workaround to fit in our design -->
            <div
              v-for="(item, j) in carouselItems"
              :key="`carousel_item-${i}-${j}`"
              class="v-item-group theme--light"
            >
              <button
                type="button"
                class="v-carousel__controls__item v-btn v-btn--icon v-btn--round theme--light v-size--small"
                :class="
                  currentCarousel === j ? 'v-item--active v-btn--active' : ''
                "
                style="color: white"
                @click="currentCarousel = j"
              >
                <span class="v-btn__content">
                  <i
                    class="v-icon notranslate mdi mdi-circle theme--light"
                    style="font-size: 18px"
                  />
                </span>
              </button>
            </div>
          </div>
        </v-carousel-item>
      </div>
    </v-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselItems: [
        {
          imgUrl:
            'https://non-terraform-prod-assets.s3.eu-west-1.amazonaws.com/onboarding/onboarding_create.png',
          title: 'Create',
          info: 'You create campaigns and content for your event community. Choose from our engaging templates or customise your own.',
        },
        {
          imgUrl:
            'https://non-terraform-prod-assets.s3.eu-west-1.amazonaws.com/onboarding/onboarding_empower.png',
          title: 'Empower',
          info: 'You share Gleanin campaign links with your event community. Add to registration confirmation pages or send via email.',
        },
        {
          imgUrl:
            'https://non-terraform-prod-assets.s3.eu-west-1.amazonaws.com/onboarding/onboarding_share.png',
          title: 'Share',
          info: 'Your audience selects their favourite content and shares straight to social. No need for them to download an app. It’s all web-based.',
        },
      ],
      currentCarousel: 0,
    }
  },
}
</script>

<style scoped>
.carousel-img {
  max-width: 523px;
  max-height: 350px;
  margin: 0 auto;
}
.carousel-title_font {
  font-size: 2.125rem !important;
  font-weight: 700;
  line-height: 2.5rem;
}
.carousel-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  max-width: 600px;
  margin: 0 auto;
}
.catamaran {
  font-family: 'Catamaran', sans-serif !important;
}
</style>
